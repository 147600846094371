import React from "react";
import { Body, MainContainer, Root, Title } from "./styledComponents";
import TitleContainer from "../../atoms/TitleContainer";
import TitleText from "../../atoms/TitleText";
import BodyText from "../../atoms/BodyText";
import BodyContainer from "../../atoms/BodyContainer";

const Legals = () => (
  <Root>
    <MainContainer>
      <TitleContainer>
        <TitleText>Mentions légales</TitleText>
        <BodyText>En vigueur au 07/06/2022</BodyText>
      </TitleContainer>
      <BodyContainer>
        <Body>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          utilisateurs et visiteurs, ci-après l""Utilisateur", du site sympl.fr
          , ci-après le "Site", les présentes mentions légales. La connexion et
          la navigation sur le Site par l’Utilisateur implique acceptation
          intégrale et sans réserve des présentes mentions légales. Ces
          dernières sont accessibles sur le Site à la rubrique « Mentions
          légales ».
        </Body>
        <Title>ARTICLE 1 - L'EDITEUR</Title>
        <Body>
          L'édition du Site est assurée par DAT SAS au capital de 444 445,00
          euros, immatriculée au Registre du Commerce et des Sociétés de PARIS
          sous le numéro 827776162 dont le siège social est situé au 259 rue
          Saint Honoré, Numéro de téléphone 0176420822, Adresse e-mail :
          contact@sympl.fr. N° de TVA intracommunautaire : FR70827776162 Le
          Directeur de la publication est MARC DJAN ci-après l'"Editeur".
        </Body>
        <Title>ARTICLE 2 - L'HEBERGEUR</Title>
        <Body>
          L'hébergeur du Site est la société AWS FRANCE, dont le siège social
          est situé au 52 rue du Port - 92000 Nanterre.
        </Body>
        <Title>ARTICLE 3 - ACCES AU SITE</Title>
        <Body>
          Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
          majeure, interruption programmée ou non et pouvant découlant d’une
          nécessité de maintenance. En cas de modification, interruption ou
          suspension du Site, l'Editeur ne saurait être tenu responsable.
        </Body>
        <Title>ARTICLE 4 - COLLECTE DES DONNEES</Title>
        <Body>
          Le Site assure à l'Utilisateur une collecte et un traitement
          d'informations personnelles dans le respect de la vie privée
          conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés. 1 En vertu de la loi
          Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
          dispose d'un droit d'accès, de rectification, de suppression et
          d'opposition de ses données personnelles. L'Utilisateur exerce ce
          droit : · par mail à l'adresse email contact@sympl.fr · via son espace
          personnel ; Toute utilisation, reproduction, diffusion,
          commercialisation, modification de toute ou partie du Site, sans
          autorisation de l’Editeur est prohibée et pourra entraîner des actions
          et poursuites judiciaires telles que notamment prévues par le Code de
          la propriété intellectuelle et le Code civil.
        </Body>
      </BodyContainer>
    </MainContainer>
  </Root>
);

export default Legals;
